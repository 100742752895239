import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white">
      <nav className="flex justify-between max-w-4xl mx-auto">
        <div className="flex flex-col">
          <p className="font-bold mb-4">Contact</p>
          <a
            className="no-underline text-sm mb-2"
            href="https://github.com/Up-Hawk"
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@uphawk.systems
          </a>
          <a
            className="no-underline text-sm mb-8"
            href="https://github.com/Up-Hawk"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@uphawk.systems
          </a>
          <a
            className="text-indigo-600 text-sm"
            href="https://github.com/Up-Hawk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <p className="font-bold text-lg">uphawk systems</p>
      </nav>
    </footer>
  );
};

export default Footer;
